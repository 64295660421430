.ng-select .ng-arrow {
  display: none !important;
}

@mixin custom-arrow {
  display: inline-block;
  height: 8px;
  width: 8px !important;
  transform-origin: center center;
  border-top: 1px solid #999999 !important;
  border-left: 1px solid #999999 !important;
  opacity: 1;
  margin: 0 7px 0 10px;
  right: 3px;
  border-bottom-right-radius: 15px;
}

.ng-arrow-wrapper {
  @include custom-arrow;
  transform: rotate(225deg);
  top: -3px;
  right: 3px;
}

.ng-select-opened .ng-arrow-wrapper {
  @include custom-arrow;
  transform: rotate(45deg);
  top: 3px;
  right: 3px;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #686e75 !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: 36px;
  bottom: 1px;
}

.invalid-required.ng-select.is-invalid .ng-select-container {
  border-color: red !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("/assets/svg/warning.svg");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.normal-ng-select .ng-select-container {
  border-radius: 4px !important;
  border: 1px solid #A4A4A4 !important;
  background-color: #ffffff !important;
}

.contract {
  .ng-select.ng-select-single .ng-select-container {
    height: 36px;
  }
}

.custom-box-search {
  .ng-select.ng-select-single .ng-select-container {
    background: transparent !important;
  }
}

.custom-box-read-only {
  .ng-arrow-wrapper {
    display: none
  }

  .ng-select.ng-select-single .ng-select-container {
    height: 36px;
    box-shadow: none;
    border: none;
    border-radius: unset;
    background-color: transparent;
    border-bottom: 1px solid lightgray;
    bottom: 0;
  }
}

.custom-placeholder {
  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    font-weight: 200;
  }
}

.custom-address {
  .ng-select-container .ng-value-container .ng-value {
    background-color: white !important;
    border: 1px solid #2163AE;
    border-radius: 4px !important;
    font-size: 0.8em !important;
  }

  .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
    border: none !important;
  }

  .ng-select-container .ng-value-container {
    label {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    display: flex;

  }

  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    padding-top: 3px;
  }
}

.search-filer-company {
  .ng-select.ng-select-single .ng-select-container {
    height: 37px !important;
  }
}

.dropdown-role {
  .ng-select.ng-select-single .ng-select-container {
    background-color: transparent !important;
    border: none !important;
  }
}

.custom-ng-select-ab {

  // Dropdown Options
  .ng-dropdown-panel {
    display: block;
    position: static;
    margin-top: 1.5rem;
    border-radius: 8px;
    border-top-color: #ccc;

    .scroll-host {
      border-radius: 8px;
      overflow-y: scroll;
      /* Enable scrolling */
      scrollbar-width: none;
      /* Firefox */
      -ms-overflow-style: none;
      /* Internet Explorer and Edge */
    }

    .scroll-host::-webkit-scrollbar {
      display: none;
      /* Chrome, Safari, Opera */
    }

    .ng-dropdown-panel-items .ng-option {
      padding: 12px;
    }

    .ng-dropdown-panel-items .ng-option.ng-option-selected {
      background-color: unset
    }
  }

  /* custom-checkbox */
  .custom-checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
  }

  .custom-checkbox input[type="checkbox"] {
    display: none;
  }

  .custom-checkbox .checkmark {
    width: 16px;
    height: 16px;
    border: 1px solid #9b9b9b;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    margin-right: 8px;
    transition: background-color 0.2s, border-color 0.2s;
  }

  .custom-checkbox input[type="checkbox"]:checked+.checkmark {
    background-color: var(--c);
    border-color: var(--c);
  }

  /* Checkmark icon (✓) */
  .custom-checkbox input[type="checkbox"]:checked+.checkmark::after {
    content: "";
    position: absolute;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .company-detail-user {
    .ng-select.ng-select-single .ng-select-container{
    height: 18px !important;
  }}
}