@font-face {
  font-family: 'helvethaica';
  src: url('helvethaica/DB-Helvethaica-X-Med-v3.2.ttf');
  /* src: url('helvethaica/DB-Helvethaica-X-v3.2.ttf'); */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'helvethaica';
  src: url('helvethaica/DB-Helvethaica-X-Med-It-v3.2.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'helvethaica';
  src: url('helvethaica/DB-Helvethaica-X-Med-It-v3.2.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'helvethaica';
  src: url('helvethaica/DB-Helvethaica-X-v3.2.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'THSarabunNew';
  src: url('THSarabunNew.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'THSarabunNewBold';
  src: url('THSarabunNewBold.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'THSarabunNewBoldItalic';
  src: url('THSarabunNew BoldItalic.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'THSarabunNewItalic';
  src: url('THSarabunNew Italic.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'TFPimpakarn';
  src: url('TFPimpakarn.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'TFPimpakarnBold';
  src: url('TFPimpakarnBold.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'TFPimpakarnBoldItalic';
  src: url('TFPimpakarnBoldItalic.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'TFPimpakarnItalic';
  src: url('TFPimpakarnItalic.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'TFSrivichaiItalic';
  src: url('TFSrivichaiItalic.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'TFSrivichai';
  src: url('TFSrivichai.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'TFSrivichaiBold';
  src: url('TFSrivichaiBold.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'TFSrivichaiBoldItalic';
  src: url('TFSrivichaiBoldItalic.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'TFSrivichaiItalic';
  src: url('TFSrivichaiItalic.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Prompt';
  src: url('Bai_Jamjuree/BaiJamjuree-Medium.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Prompt';
  src: url('Bai_Jamjuree/BaiJamjuree-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Prompt';
  src: url('Bai_Jamjuree/BaiJamjuree-SemiBold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Charmonman';
  src: url('Charmonman/Charmonman-Regular.ttf') format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: 'Charmonman';
  src: url('Charmonman/Charmonman-Regular.ttf') format("truetype");
  font-weight: bold;
}


/* this is old font, it will be removed soon*/
@font-face {
  font-family: 'AngsanaNew';
  src: url('AngsanaNew.ttf');
  font-weight: normal;
}
@font-face {
  font-family: 'AngsanaNewBold';
  src: url('AngsanaNewBold.ttf');
  font-weight: normal;
}
@font-face {
  font-family: 'AngsanaNewBoldItalic';
  src: url('AngsanaNewBoldItalic.ttf');
  font-weight: normal;
}
@font-face {
  font-family: 'AngsanaNewItalic';
  src: url('AngsanaNewItalic.ttf');
  font-weight: normal;
}
@font-face {
  font-family: 'CordiaNewItalic';
  src: url('CordiaNewItalic.ttf');
  font-weight: normal;
}
@font-face {
  font-family: 'CordiaNew';
  src: url('CordiaNew.ttf');
  font-weight: normal;
}
@font-face {
  font-family: 'CordiaNewBold';
  src: url('CordiaNewBold.ttf');
  font-weight: normal;
}
@font-face {
  font-family: 'CordiaNewBoldItalic';
  src: url('CordiaNewBoldItalic.ttf');
  font-weight: normal;
}
@font-face {
  font-family: 'CordiaNewItalic';
  src: url('CordiaNewItalic.ttf');
  font-weight: normal;
}

