.chrome-picker {
  box-shadow: none !important;
  width: 215px !important;
}

.chrome-hue {
  margin-top: 8px !important;
}

color-editable-input span {
  margin-top: 9px !important;
  margin-right: 0.5rem !important;
  color: #1E1E1E !important;
  font-size: 14px !important;
  font-family: 'Prompt', sans-serif !important;
}

color-editable-input input {
  text-align: left !important;
  padding: 1rem !important;
  color: #7A7A7A !important;
  font-family: 'Prompt', sans-serif !important;
}

.chrome-toggle {
  display: none !important;
}

.chrome-color {
  display: none !important;
}

.chrome-body {
  padding: 14px 0 14px 0 !important;
}

.wrap {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
