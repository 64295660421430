.modal-body {
  padding: 0;
}

.modal-header {
  background-color: white;
}

.modal-banner {
  .modal-dialog {
    max-width: 1240px !important;
  }

  .modal-content {
    border-radius: 8px !important;
    margin: 1rem !important;
  }
}

.modal-mobile {
  .modal-dialog {
    max-width: fit-content !important;
  }
}

@media screen and (max-width: 1280px) {
  .modal-banner {
    .modal-dialog {
      max-width: 1140px !important;
    }
  }
}

@media screen and (max-width: 577px) {
  .modal-banner {
    .modal-dialog {
      max-width: 315px !important;
      margin: auto;
    }
  }
}

.custom-modal-width {
  .modal-dialog {
    max-width: 650px;
  }
}

.modal-banner-mobile {
  .modal-dialog {
    max-width: 100vh !important;
  }
}



.multi-pdf-modal {
  @media screen and (min-width: 992px){
      min-width: 992px !important;
  }
}